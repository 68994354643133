<template>
  <v-footer absolute class="d-flex flex-column align-center no-print">
    <env-chip />
    <!--    <div>-->
    <!--      <lang-select />-->
    <!--    </div>-->
    <div class="text-center body-2">
      © Copyright {{ year }} - {{ $t("copyright") }}
    </div>
    <!-- <a href="#" @click="changeLocale('pt-BR')"><flag iso="br"></flag></a>
    <a href="#" @click="changeLocale('en')"><flag iso="us"></flag></a>
    <a href="#" @click="changeLocale('es')"><flag iso="es"></flag></a> -->
  </v-footer>
</template>

<script>
export default {
  components: {
    // "lang-select": () => import("@/presentation/layout/LangSelect"),
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    isLoginPage() {
      return this.$route.name === "login";
    },
  },
};
</script>

<style></style>
